// @flow strict
import React from "react";
import { withPrefix, Link } from "gatsby";
import styles from "./Author.module.scss";
import Burger from "@animated-burgers/burger-rotate";
import "@animated-burgers/burger-rotate/dist/styles.css";
import "./Burger.scss";

type Props = {
  author: {
    name: string,
    bio: string,
    photo: string
  },
  isIndex: ?boolean,
  isActive: ?boolean
};

const Author = ({ author, isIndex, isActive, useIsActive }: Props) => (
  <div className={styles["author"]}>
    <Link to="/">
      <img
        src={withPrefix(author.photo)}
        className={styles["author__photo"]}
        width="75"
        height="75"
        alt={author.name}
      />
    </Link>
    <Burger
      style={{
        position: "absolute",
        top: 10,
        right: 10
      }}
      onClick={() => useIsActive(!isActive)}
      isOpen={isActive}
    />

    {isIndex === true ? (
      <h1 className={styles["author__title"]}>
        <Link className={styles["author__title-link"]} to="/">
          {author.name}
        </Link>
      </h1>
    ) : (
      <h2 className={styles["author__title"]}>
        <Link className={styles["author__title-link"]} to="/">
          {author.name}
        </Link>
      </h2>
    )}
    <p
      className={styles["author__subtitle"]}
      style={isActive ? {} : { display: "none" }}
    >
      {author.bio}
    </p>
  </div>
);

export default Author;
